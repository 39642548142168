import bootstrap from "bootstrap";

const registerForms = Array.from(document.getElementsByClassName('subm-google-form'));

const modals = [...document.getElementsByClassName('reg-form')];

const getFormData = form => new URLSearchParams(new FormData(form));

const closeModal = element => {
    const modal = bootstrap.Modal.getInstance(element);
    if(Boolean(modal)) modal.hide();
}

const closeModals = () => modals.forEach(closeModal);

const register = e => {
    e.preventDefault();
    const data = getFormData(e.target);
    fetch(e.target.action, {
        method: "POST",
        body: data,
    })
        .then((response) => response.json())
        .catch((err) => console.log(err))
        .finally(function () {
            closeModals();
            const myModal = new bootstrap.Modal('#succes');
            myModal.show();
        });
};

registerForms.forEach((form) => form.addEventListener('submit', register));